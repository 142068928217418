import React, {useEffect} from 'react';
import {Link, graphql} from 'gatsby';
import {getSrc} from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import BlogView from '../components/BlogView';
import './blog-post-content.css';
import Seo from '../components/SEO';

const BlogPost = ({data, location}) => {
  const {frontmatter, excerpt} = data.markdownRemark;
  const {image, title} = frontmatter;
  useEffect(() => {
    location?.state?.scrollY && window.scroll(0, location?.state?.scrollY);
  }, [location?.state?.scrollY]);
  return (
    <Layout seo={<Seo type="article" image={getSrc(image?.childImageSharp)} title={title} description={excerpt} />}>
      <div className="mx-auto max-w-[42rem] ">
        <article className="pt-24 max-w-[42rem]" itemScope itemType="http://schema.org/Article">
          <BlogView summary={false} post={data.markdownRemark} />
          <footer className="my-24 text-center">
            <Link className="px-4 py-4 text-sapphire hover:underline" to="/blog">
              ← Back to index
            </Link>
          </footer>
        </article>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogPostBySlug($id: String) {
    markdownRemark(id: {eq: $id}) {
      fields {
        slug
      }
      html
      frontmatter {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        date(formatString: "MMMM DD, YYYY")
        author
        tags
      }
      excerpt(pruneLength: 120, format: PLAIN)
    }
  }
`;

export default BlogPost;
